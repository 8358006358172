<template>
  <a-spin :spinning="loading">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Request Troubleshoot</h2>
      </div>
      <!-- <div>
        <a-button @click.prevent="" type="danger">
          <LeftSquareOutlined />
          Batal</a-button
        >
      </div> -->
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <!-- <a-button @click="changeEdittable">
            <span v-if="!editable"> <FormOutlined class="mr-2" />Edit </span>
            <span v-else> <EyeOutlined class="mr-2" />View </span>
          </a-button> -->
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Date</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :sm="{ span: 6 }">
                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Section 1 - Troubleshooter Detail</h1>
          </div>
          <a-row :gutter="[24, 12]">
            <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;">
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Name</p>
              </div>
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Title</p>
              </div>
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Company</p>
              </div>
              <div class="w-100"></div>
            </a-col>
            <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;"
              v-for="(item, index) in troubleshootDetailData" :key="index">
              <div class="w-100">
                <a-input placeholder="Masukkan Nama"></a-input>
              </div>
              <div class="w-100">
                <a-input placeholder="Masukkan Title"></a-input>
              </div>
              <div class="w-100">
                <a-input placeholder="Masukkan Company"></a-input>
              </div>
              <div class="w-100">
                <a-button @click="deleteTroubleshootDetail(item._id)" type="danger">
                  <DeleteOutlined />
                </a-button>
              </div>
            </a-col>
            <a-col :span="24">
              <a-button @click="addTroubleshootDetail">
                <PlusOutlined />
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Section 2 - Troubleshoot Requester</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Nama</p>
                <a-select class="w-100 " placeholder="Pilih User">
                  <a-select-option value="Shul">Shul</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Title</p>
                <a-input class=" " placeholder="Masukkan Title"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input class=" " placeholder="Masukkan Email"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input class=" " placeholder="Masukkan Nomor Telepon"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Section 3 - Aset Detail</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :span="24">
                <h2 class="text-subtitle">3.1 - Aset Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :span="10">
                <p class="text-label">Category</p>
                <a-select class="w-100 " placeholder="Pilih Aset">
                  <a-select-option value="Access Point">Access Point</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="14">
                <p class="text-label">Facility</p>
                <a-select class="w-100 " placeholder="Pilih Aset">
                  <a-select-option value="Access-002">Access-002</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Brand</p>
                <a-input placeholder="Masukkan Brand"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Model</p>
                <a-input placeholder="Masukkan Model"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Nomor Produk</p>
                <a-input placeholder="Masukkan Nomor Produk"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Nomor Serial</p>
                <a-input placeholder="Masukkan Nomor Serial"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Service Tag</p>
                <a-input placeholder="Masukkan Service Tag"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Asset Tag</p>
                <a-input placeholder="Masukkan Asset Tag"></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24">
                <h2 class="text-subtitle">3.2 - Procurement Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Tanggal Pembelian</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :span="12">
                <p class="text-label">Nomor Pembelian</p>
                <a-input placeholder="Masukkan Nomor Pembelian"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi</p>
                <a-input placeholder="Masukkan Garansi" suffix="Month(s)"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Mulai</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Berakhir</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :span="12">
                <p class="text-label">Vendor</p>

                <a-select placeholder="Pilih Vendor" class="w-100">
                  <a-select-option value="Acer">Acer</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input placeholder="Masukkan Email"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input placeholder="Masukkan Nomor Telepon"></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24">
                <h2 class="text-subtitle">3.3 - Maintenance Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Tanggal Kontrak</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Nomor Kontrak</p>
                <a-input placeholder="Masukkan Nomor Kontrak"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi</p>
                <a-input placeholder="Masukkan Garansi" suffix="Month(s)"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Mulai</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Berakhir</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Vendor</p>

                <a-select placeholder="Pilih Vendor" class="w-100">
                  <a-select-option value="Acer">Acer</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input placeholder="Masukkan Email"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input placeholder="Masukkan Nomor Telepon"></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5" v-if="asset_category != 2 && asset_category != 3">
              <a-col :span="24">
                <h2 class="text-subtitle">3.4 - Tecnical Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :span="8">
                <p class="text-label">System Name</p>
                <a-input placeholder="Masukkan System Name"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Host Name</p>
                <a-input placeholder="Masukkan Host Name"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Domain Role</p>
                <a-input placeholder="Masukkan Domain Role"></a-input>
              </a-col>
              <a-col :span="6">
                <p class="text-label">DNS</p>
                <a-input placeholder="Masukkan DNS"></a-input>
              </a-col>
              <a-col :span="6">
                <p class="text-label">IP Address</p>
                <a-input placeholder="Masukkan IP Address"></a-input>
              </a-col>
              <a-col :span="12">
                <p class="text-label">OS / Nomor Serial</p>
                <a-input placeholder="Masukkan OS / Nomor Serial"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Section 4 - Post Troubleshoot Remarks</h1>
          </div>
          <a-row :gutter="[24, 12]">
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Type of Failure</p>
              <!-- <a-input placeholder="Masukkan Type of Failure"></a-input> -->
              <a-select placeholder="Pilih Type of Failure" class="w-100">
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option v-for="(item, index) in dataTypeofFailure" :key="index" :value="item">{{ item
                }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Type of Solving</p>
              <!-- <a-input placeholder="Masukkan Type of Solving"></a-input> -->
              <a-select placeholder="Pilih Type of Solving" class="w-100">
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option v-for="(item, index) in dataTypeofSolving" :key="index" :value="item">{{ item
                }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Reparation</p>
              <!-- <a-input placeholder="Masukkan Reparation"></a-input> -->
              <a-select placeholder="Pilih Reparation" class="w-100">
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option v-for="(item, index) in dataReparation" :key="index" :value="item">{{ item
                }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 14 }" :span="14">
              <p class="text-label">Explain</p>
              <a-textarea placeholder="Masukkan Explain"></a-textarea>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <a-button @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'

const columns = [
  {
    title: 'Nama Perangkat',
    dataIndex: 'device',
    key: 'device',
    slots: { customRender: 'device' },
    width: '15%',
  },
  {
    title: 'Nama Brand',
    dataIndex: 'brand',
    key: 'brand',
    slots: { customRender: 'brand' },
  },
  {
    title: 'Nomor Produk',
    dataIndex: 'productNo',
    key: 'productNo',
    slots: { customRender: 'productNo' },
  },
  {
    title: 'Nomor Serial',
    dataIndex: 'serialNo',
    key: 'serialNo',
    slots: { customRender: 'serialNo' },
  },
]

export default {
  components: { LeftOutlined, DeleteOutlined, PlusOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()
    const assets = ref()
    const manufactures = ref()
    const loading = ref(false)
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const selectedTypeofFailure = ref()
    const selectedTypeofSolving = ref()
    const selectedReparation = ref()
    const editable = ref(false)
    const troubleshootDetailData = ref([])
    const dataTypeofFailure = [
      'Hardware Failure',
      'Software Failure',
      'System Failure',
      'Part Failure',
      'Broken Part',
      'Other',
    ]
    const dataTypeofSolving = ['Reparation', 'Part Replacement', 'Reconfiguration']
    const dataReparation = ['Minor', 'Major', 'Overhaul', 'Other']
    // const getAssets = () => {
    //   const asset = assetsFile[13].data[route.params.id]
    //   const assetCatId = asset_category
    //   const assetSubCatId = asset_sub
    //   const assetCategory = assetsFile[15].data.find(da => da.category == assetCatId)
    //   let tableAssetByCatId
    //   switch (assetCatId) {
    //     case '1':
    //       tableAssetByCatId = 2
    //       break
    //     case '2':
    //       tableAssetByCatId = 30
    //       break
    //     case '3':
    //       tableAssetByCatId = 55
    //       break
    //     case '4':
    //       tableAssetByCatId = 65
    //       break
    //     case '5':
    //       tableAssetByCatId = 45
    //       break
    //     case '6':
    //       tableAssetByCatId = 2
    //       break
    //     default:
    //       tableAssetByCatId = 2
    //       break
    //   }
    //   const detailAsset = assetsFile[tableAssetByCatId].data.find(da => da.id == assetSubCatId)
    //   // console.log(detailAsset, '<<<<<')
    //   return {
    //     asset,
    //     detailAsset,
    //     assetCategory,
    //   }
    // }
    // onBeforeMount(() => {
    //   getInitialData()
    // })
    // // created() {

    // // }
    // const getInitialData = () => {
    //   loading.value = true
    //   const asset = getAssets()
    //   const manufacture = getManufacture()
    //   value = asset
    //   value = manufacture
    //   catFacility.value = detailname
    //   facility.value = asset_name
    //   // console.log(assets, '<<<<<HALLO')
    //   loading.value = false
    // }
    // const getCurrentDate = computed(() => {
    //   return moment()
    // })

    // const getManufacture = () => {
    //   const manufactureId = assetsFile[13].data[route.params.id].manufacturer_id
    //   const manufacture = assetsFile[50].data.find(da => da.id == manufactureId)

    //   return manufacture
    // }

    const addTroubleshootDetail = () => {
      troubleshootDetailData.value.push({ _id: uniqueId('tbs-'), name: '', title: '', company: '' })
    }
    const deleteTroubleshootDetail = id => {
      troubleshootDetailData.value = troubleshootDetailData.value.filter(trb => trb._id !== id)
    }

    // const changeEdittable = () => {
    //   editable.value = !editable.value
    // }
    const handleSubmit = () => {
      notification.success({
        message: 'Berhasil Submit',
        description: 'Data Troubleshoot Berhasil di Submit',
      })
      setTimeout(() => {
        router.push({ name: 'Troubleshoot' })
      }, 1000)
    }
    return {
      // assetsFile,
      // assets,
      // getAssets,
      // getManufacture,
      // manufactures,
      loading,
      columns,
      // detail troubleshoot
      facility,
      catFacility,
      troubleshootDetailData,
      // Last Section
      dataTypeofFailure,
      dataTypeofSolving,
      dataReparation,
      selectedReparation,
      selectedTypeofFailure,
      selectedTypeofSolving,
      // editable,

      // getCurrentDate,
      // changeEdittable,

      addTroubleshootDetail,
      deleteTroubleshootDetail,
      handleSubmit,
      // dataSource,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
